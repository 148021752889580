<template>
  <modal
    name="second-step-verification"
    transition="pop-out"
    class="second-step-verification"
    @before-close="beforeClose"
    :width="400"
    :focus-trap="true"
    :height="500"
  >
    <div class="boxVerification">
      <div class="" id="bp-left">
        <div class="partition" id="partition-verification">
          <div class="partition-title">2-Step verification</div>

          <div class="col-md-12" style="text-align: center">
            <img
              :src="companyLogo || companyLogoPlaceholder"
              class="site-rounded-logo rounded"
            />
          </div>
          <div class="partition-form">
            <form autocomplete="false">
              <div class="col-md-11 form-container">
                <div class="col-md-12" style="text-align: left">
                  <h3>Enter the token we just sent you via email</h3>
                </div>
                <div class="col-md-12">
                  <input
                    class="form-control"
                    v-model="verificationData.token"
                    type="password"
                    autocomplete="off"
                    required
                    placeholder="Enter token (6-digit)"
                    :maxlength="6"
                  />
                </div>
                <div class="col-md-12">
                  <button
                    type="submit"
                    class="mb-4 mt-4 col-md-12 btn btn-black"
                    @click="verifytoken"
                    :disabled="inValidInputs || loading"
                  >
                    <span
                      ><i
                        class="mr-2 fas fa-check-circle fa-fw"
                        v-if="!loading"
                      ></i>
                      <i
                        class="mr-2 fa fa-spin fa-circle-notch"
                        v-if="loading"
                      ></i>
                      Verify</span
                    >
                  </button>
                </div>
                <a href="#" @click.prevent="!loading && closeModal()"
                  >Start Over</a
                >
                <div class="mt-4">
                  Need help? Contact
                  <a href="mailto:info@communitycareconnection.com"
                    >Our Support</a
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "TwoStepVerificationModal",
  data() {
    return {
      loading: false,
      verificationData: {
        token: "",
        //trustThisDevice: '',
      },
      companyLogoPlaceholder: require("@/assets/img/c3-logo.png"),
    };
  },
  computed: {
    ...mapState({
      companyLogo: (state) => state.settings.companyLogo,
    }),
    ...mapState(["auth"]),
    inValidInputs() {
      return this.verificationData.token.length != 6;
    },
  },
  methods: {
    ...mapActions({
      doVerification: "auth/secondStepVerification",
      getUserDetails: "auth/getUser",
      doLogout: "auth/logout",
    }),
    verifytoken: function (e) {
      e.preventDefault();
      let vm = this;
      this.loading = true;
      this.doVerification({
        token: this.verificationData.token,
      })
        .then(function () {
          vm.$modal.hide("second-step-verification");
          vm.loading = false;
          vm.$router.push({ name: "dashboard" }, function () {
            vm.getUserDetails().then(() => {
              vm.showPrivacyModal();
            });
          });
        })
        .catch(function (err) {
          vm.loading = false;
          if (!err.accessDenied) {
            Swal.fire({
              title: "Login Failed",
              text: err.message || (err.data || {}).error,
              icon: "error",
            });
          }
        });
    },
    showPrivacyModal: function () {
      if (!localStorage.getItem("PRIVACY_ACCEPTED")) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black",
            cancelButton: "btn btn-danger ml-4",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons
          .fire({
            icon: "warning",
            title: "Terms & Conditions",
            html: `<div class="justify-text">By clicking the "I Accept" button displayed as part of the software loading process, you agree to the following terms and conditions (The "agreement") covering your use of the services and the C3 website whether in connection with a paid or trial period subscription. If you are entering into this agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to these terms and conditions, in which case the terms "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with these terms and conditions, you must select the "I Decline" button and may not use the service.</div>`,
            showCancelButton: true,
            confirmButtonText: "I Accept",
            cancelButtonText: "I Decline",
            allowOutsideClick: false,
            allowEscapeKey: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              localStorage.setItem("PRIVACY_ACCEPTED", true);
            } else {
              this.doLogout().then((res) => {
                if (res) {
                  this.$router.push({ path: "/" });
                  window.location.href = `${window.location.origin}`;
                }
              });
            }
          });
      }
    },
    beforeClose: function () {
      if (this.auth.authenticating && !this.auth.authenticated) {
        this.doLogout();
      }
    },
    closeModal: function () {
      this.doLogout().then(() => {
        this.$modal.hide("second-step-verification");
      });
    },
  },
};
</script>
<style lang="scss">
.second-step-verification {
  .v--modal {
    border-radius: 36px;
  }
}
.partition-form {
  margin-top: 20px;
}
.boxVerification {
  background: white;
  width: 400px;
  height: 500px;
  border-radius: 36px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #254b7b;
  font-size: 12px;
  a {
    color: #03a9f4;
    font-size: 15px;
  }
  .form-container {
    text-align: center;
    margin: 0 auto;
  }

  input[type="checkbox"] {
    transform: scale(1.2);
  }
  .partition {
    width: 100%;
    height: 100%;
    .partition-title {
      box-sizing: border-box;
      padding: 30px;
      padding-top: 10px;
      padding-bottom: 0px;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 30px;
      font-weight: 300;
    }
  }
  .large-btn {
    background: white;
    span {
      font-weight: 600;
    }
    &:hover {
      color: white !important;
    }
  }
}
.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}
.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
.checkboxLabel {
  font-size: 17px;
  line-height: 17px;
  position: relative;
  bottom: 1px;
}

@media (max-width: 767px) {
  .second-step-verification {
    .v--modal {
      width: 90% !important;
      left: 5% !important;

      .boxVerification {
        width: 100%;
      }
    }
  }
}
</style>
